import useResizeObserver from "@react-hook/resize-observer";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { PlayArrow } from "@material-ui/icons";
import BugReportIcon from "@material-ui/icons/BugReport";
import { IS_DEV_ENV, IS_INTERNAL_ENV } from "constants/app.constants";
import { ALL_PRODUCT_TYPES } from "constants/chart.constants";
import {
  SHAPEFILE_LABELS_LAYER,
  SHAPEFILE_NAME_LABELS_LAYER,
  WELL_LAYER,
  WELL_LAYER_POINT
} from "constants/mapLayers.constants";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  IMapRequest,
  setIsDebuggingMap,
  setSelectedWells,
  setViewLock,
  updateLayers
} from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { BaseIconToggle, BaseTooltip, ErrorBoundary } from "components/base";
import HorizontalLoading from "components/base/HorizontalLoading";
import { useDashboardContext } from "components/dashboard/hooks";
import useShowExploitableInMap from "components/exploitable-report/hooks/useShowExploitableInMap";
import GeomBinDrawer from "components/geom-bin/GeomBinDrawer";
import { useGeomBinContext } from "components/geom-bin/hooks/useGeomBinContext";
import AddPolygon from "components/geom-bin/toolbar/AddPolygon";
import DeletePolygon from "components/geom-bin/toolbar/DeletePolygon";
import TownshipRangeGrid from "components/map/TownshipRangeGrid";
import { useProjectContext } from "components/project/projects/context";
import Indicator from "components/ui/Indicator";
import { useWorkspaceContext } from "components/workspace/hooks/useWorkspaceContext";

import useBetaFeatures from "../../hooks/useBetaFeatures";
import { EntityKind } from "../../models/entityKind";
import GoToSyncWells from "../sync/GoToSyncWells";
import { FacilityHighlighter } from "./FacilityHighlighter";
import "./Map.scss";
import MapMessage from "./MapMessage";
import MapToolbar from "./MapToolbar";
import MapboxWrapper from "./MapboxWrapper";
import ShowSticksOnMap from "./ShowSticksOnMap";
import Timeline from "./Timeline";
import WellDetail from "./WellDetail";
import { IpdbProvider } from "./contexts/IpdbContext";
import { MapProvider } from "./contexts/MapContext";
import { LABEL_SHAPEFILE_TYPE } from "./hooks/manage-mapbox-shapefile-loading/utils";
import useMapUpdater from "./hooks/useMapUpdater";
import useTypeLogWellsLayer from "./hooks/useTypeLogWellsLayer";
import { IpdbLegend } from "./legend/IpdbLegend";
import Screenshot from "./screenshot/Screenshot";
import BaseLayerToggle from "./toolbar/BaseLayerToggle";
import { BubbleVisLegend } from "./toolbar/BubbleVisLegend";
import GoToTownshipPlay from "./toolbar/GoToTownshipPlay";
import Ipdb from "./toolbar/Ipdb";
import LassoSelection from "./toolbar/LassoSelection";
import MapFullscreen from "./toolbar/MapFullscreen";
import MapLock from "./toolbar/MapLock";
import MapVis from "./toolbar/MapVis";
import XdaLineMarker from "./toolbar/XdaLineMarker";
import ZoomControl from "./toolbar/ZoomControl";
import { getFeaturesIntersectingPolygon } from "./utils/mapboxHelper";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function Map({ id, onFullscreen = null }): JSX.Element {
  //states
  const mapboxRef = useRef<mapboxgl.Map>(undefined);
  const [, setMapSync] = useState(true);
  const mapSyncRef = useRef(true);
  const drawRef = useRef(undefined);
  const portalRef = useRef<HTMLDivElement>(undefined);
  const { hasFeature } = useBetaFeatures();

  const [showTimeline, setShowTimeline] = useState(false);
  const [isTownshipRangeGridVisible, setIsTownshipRangeGridVisible] = useState(false);
  const user = useSelector((state: RootState) => state.auth.user);
  const filterId = useSelector((state: RootState) => state.filter.filterId);
  const facilityFilterId = useSelector(
    (state: RootState) => state.facilityFilter.filterId
  );

  const globalNormalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.globalNormalizeBy
  );
  const useNormalizeBy = useSelector(
    (state: RootState) => state.normalizeBy.useNormalizeBy
  );
  const { isActivityBarResizing } = useWorkspaceContext();
  const { checkedLayerKeys } = useProjectContext();
  const txnId = useSelector((state: RootState) => state.map.txnId);
  const isDebuggingMap = useSelector((state: RootState) => state.map.isDebuggingMap);

  const globalGroupBy = useSelector((state: RootState) => state.groupBy.globalGroupBy);

  const globalFacilityFocus = useSelector(
    (state: RootState) => state.groupBy.globalFacilityFocus
  );
  const [groupBy, setGroupBy] = useState({ ...globalGroupBy });

  const shapefileFeatures = useSelector(
    (state: RootState) => state.project.shapefileFeatures
  );

  const lockedColors = useSelector((state: RootState) => state.app.lockedColors);
  const userSettingsOpen = useSelector(
    (state: RootState) => state.userSetting.userSettingsOpen
  );
  const selectionPolygonRef = useRef(null);

  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);

  const sortBy = useSelector((state: RootState) => state.filter.sortBy);
  const showGroupsNotInFilter = useSelector(
    (state: RootState) => state.filter.showGroupsNotInFilter
  );
  const hasCheckedBins = useSelector((state: RootState) => state.groupBy.hasCheckedBins);
  const hasCheckedBinsFacility = useSelector(
    (state: RootState) => state.groupBy.hasCheckedBinsFacility
  );
  const showFacilityGroupsNotInFilter = useSelector(
    (state: RootState) => state.facilityFilter.showGroupsNotInFilter
  );
  const dispatch = useDispatch();

  //layers
  const layers = useSelector((state: RootState) => state.map.layers);
  const layersRef = useRef(layers);

  const { isActive: isGeomBinOpen } = useGeomBinContext();
  const { updateWellAndFacilityMaps } = useMapUpdater();
  const { capabilities } = useDashboardContext();

  useShowExploitableInMap(mapboxRef.current);

  const getAndSetFeaturesIntersectingPolygon = useCallback(
    async (polygon) => {
      const { selectedWells, wellsIntersect, selectedFeatures } =
        await getFeaturesIntersectingPolygon(mapboxRef.current, polygon);
      if (!selectedWells) {
        return [];
      }
      dispatch(setSelectedWells(selectedWells));
      return [selectedWells, wellsIntersect, selectedFeatures];
    },
    [dispatch]
  );

  useEffect(() => {
    const mapbox = mapboxRef.current;
    if (!mapbox) {
      return;
    }

    layersRef.current = [...layers];
  }, [layers]);

  const localState = useRef({
    selectionMode: "single",
    xdaLineMoved: false,
    currentMouseCoordinate: { lng: 0, lat: 0 },
    shapefilesLayers: [],
    shapefilesSources: [],
    addLayer: (layer, beforeLayer = null) => {
      const mapbox = mapboxRef.current;
      if (!mapbox) {
        return;
      }

      mapbox.addLayer(layer, beforeLayer);
      if (!mapbox.getLayer(layer.id)) {
        // The layer failed to be added, so abort.
        // Error will be logged in console by mapbox.
        return;
      }

      const isVisible = mapbox.getLayoutProperty(layer.id, "visibility") !== "none";

      const lineWidth =
        layer.id === WELL_LAYER ? mapbox.getPaintProperty(layer.id, "line-width") : 0;

      const circleRadius =
        layer.id === WELL_LAYER_POINT
          ? mapbox.getPaintProperty(WELL_LAYER_POINT, "circle-radius")?.[4]
          : 0;

      const layerToAdd = {
        id: layer.id,
        isVisible: isVisible,
        minzoom: layer.minzoom,
        maxzoom: layer.maxzoom,
        lineWidth: lineWidth,
        circleRadius: circleRadius
      };

      const layers = layersRef.current;

      const idx = layers.map((l) => l.id).indexOf(layerToAdd.id);
      if (idx >= 0) {
        return;
      }

      if (beforeLayer) {
        let addIndex = layers.length - 1;

        if (addIndex < 0) {
          addIndex = 0;
        }

        //find index of layer
        const idx = layers.map((l) => l.id).indexOf(beforeLayer);
        if (idx >= 0) {
          addIndex = idx;
        }

        layers.splice(addIndex, 0, layerToAdd);
        setLayers(layers);
        return;
      }

      layers.push(layerToAdd);
      setLayers(layers);
    }
  });

  useTypeLogWellsLayer(
    mapboxRef.current,
    capabilities.hasTypeLogWidget,
    localState.current.addLayer
  );

  const setLayers = useCallback(
    (layers) => {
      const newLayers = [...layers];
      layersRef.current = layers;
      if (newLayers) {
        dispatch(updateLayers(newLayers));
      }
    },
    [dispatch]
  );

  const activeColorPalette = useSelector(
    (state: RootState) => state.userSetting.activeColorPalette
  );
  const activeFacilityColorPalette = useSelector(
    (state: RootState) => state.userSetting.activeFacilityColorPalette
  );

  useResizeObserver(containerRef, (entry) => {
    setContainerHeight(entry.contentRect.height);
    setContainerWidth(entry.contentRect.width);
  });

  useEffect(() => {
    setGroupBy({ ...globalGroupBy });
  }, [globalGroupBy]);
  useEffect(() => {
    const mapbox = mapboxRef.current;
    if (mapbox != null && !isActivityBarResizing) {
      mapbox.resize();
    }
  }, [containerWidth, containerHeight, isActivityBarResizing]);

  const getMapRequest = useCallback(
    (
      filterId,
      groupBy,
      sortBy,
      showGroupsNotInFilter,
      hasCheckedBins,
      entityKind: EntityKind
    ) => {
      if (!user) {
        return;
      }
      const bin = Object.assign({}, groupBy.bin);
      if (bin.BinSize === "") {
        bin.BinSize = 1;
      }
      if (bin.MinSize === "") {
        bin.MinSize = null;
      }
      if (bin.GreaterThan === "") {
        bin.GreaterThan = null;
      }
      if (bin.LessThan === "") {
        bin.LessThan = null;
      }
      const requestGroupBy = Object.assign({}, groupBy, { bin });
      const request: IMapRequest = {
        UserName: user.email,
        ColorPalette:
          entityKind === EntityKind.Facility
            ? activeFacilityColorPalette
            : activeColorPalette,
        ReverseColor:
          entityKind === EntityKind.Facility
            ? activeFacilityColorPalette.reverse
            : activeColorPalette.reverse,
        Product: ALL_PRODUCT_TYPES.Oil.key,
        LockStyle: false,
        FilterId: filterId,
        SortBy: sortBy,
        ShowGroupsNotInFilter: showGroupsNotInFilter,
        GetBBox: false,
        HasCheckedBins: hasCheckedBins,
        GroupBy: requestGroupBy,
        ColorLockedItems: lockedColors,
        NormalizeBySetting: Object.assign({}, globalNormalizeBy, {
          useNormalizeBy
        }),
        EntityKind: entityKind
      };
      return request;
    },
    [
      user,
      activeColorPalette,
      activeFacilityColorPalette,
      useNormalizeBy,
      lockedColors,
      globalNormalizeBy,
      filterId
    ]
  );
  useEffect(() => {
    async function mapRequest() {
      const wellRequest = getMapRequest(
        filterId,
        groupBy,
        sortBy,
        showGroupsNotInFilter,
        hasCheckedBins,
        EntityKind.Well
      );
      const facilityRequest = getMapRequest(
        facilityFilterId,
        globalFacilityFocus,
        sortBy,
        showFacilityGroupsNotInFilter,
        hasCheckedBinsFacility,
        EntityKind.Facility
      );
      await updateWellAndFacilityMaps(wellRequest, facilityRequest);
    }

    mapRequest();
  }, [
    dispatch,
    filterId,
    facilityFilterId,
    groupBy,
    globalFacilityFocus,
    showFacilityGroupsNotInFilter,
    getMapRequest,
    useNormalizeBy,
    globalNormalizeBy,
    hasCheckedBins,
    hasCheckedBinsFacility,
    sortBy,
    showGroupsNotInFilter,
    userSettingsOpen
  ]);

  const removeLayer = useCallback(
    (layerId) => {
      const mapbox = mapboxRef.current;
      if (!mapbox) {
        return;
      }
      if (mapbox.getLayer(layerId)) {
        mapbox.removeLayer(layerId);
      } else {
        return;
      }
      const layers = layersRef.current;

      const idx = layers.map((l) => l.id).indexOf(layerId);
      if (idx >= 0) {
        layers.splice(idx, 1);
      }
      setLayers([...layers]);

      //remove layer for both shapefileslayers and shapefileSources
      const selectedShapefileLayers = localState.current.shapefilesLayers;
      const selectedLayerIndex = selectedShapefileLayers
        .map((l) => l.id)
        .indexOf(layerId);
      if (selectedLayerIndex >= 0) {
        selectedShapefileLayers.splice(selectedLayerIndex, 1);
      }
    },
    [setLayers]
  );

  // TODO BF: Move this out into a hook, just to manage the shapefile layers by checked geoms
  // Update mapbox when shapefile features visibility has been toggled
  useEffect(() => {
    const mapbox = mapboxRef.current;
    if (mapbox == null) return;
    for (const shapefileKey of Object.keys(shapefileFeatures)) {
      const currentLayerName = shapefileKey;
      if (mapbox.getLayer(currentLayerName)) {
        let uncheckedFeatures = shapefileFeatures?.[shapefileKey]?.features
          .filter((f) => f.checked)
          .map((f) => f.shapefileGeomId);

        if (!uncheckedFeatures?.length) {
          uncheckedFeatures = [""];
        }

        mapbox.setPaintProperty(currentLayerName, "fill-opacity", [
          "match",
          ["get", "shapefileGeomId"],
          uncheckedFeatures,
          ["get", "opacity"],
          0
        ]);

        mapbox.setPaintProperty(`${currentLayerName}-stroke`, "line-opacity", [
          "match",
          ["get", "shapefileGeomId"],
          uncheckedFeatures,
          ["get", "opacity"],
          0
        ]);

        const textOpacity = checkedLayerKeys.includes(SHAPEFILE_LABELS_LAYER)
          ? ["match", ["get", "shapefileGeomId"], uncheckedFeatures, 1, 0]
          : 0;

        mapbox.setPaintProperty(
          `${currentLayerName}-property`,
          "text-opacity",
          textOpacity
        );
      }
    }
    if (checkedLayerKeys.length > 0 && mapbox?.getStyle()?.layers) {
      const mapboxLayers = mapbox.getStyle().layers;
      const showShapefileName = checkedLayerKeys.includes(SHAPEFILE_NAME_LABELS_LAYER);
      for (const layer of mapboxLayers) {
        if (layer.id.includes(LABEL_SHAPEFILE_TYPE)) {
          mapbox.setPaintProperty(layer.id, "text-opacity", showShapefileName ? 1 : 0);
        }
      }
    }
  }, [shapefileFeatures, checkedLayerKeys]);

  function onSyncChanged(sync) {
    if (sync) {
      dispatch(setViewLock(true));
    } else {
      dispatch(setViewLock(false));
    }
  }

  //HorizontalLoading checks if the map is uninitialized, mapBusy is true, or shapefileQuery is fetching
  return (
    <div
      className={`Mapbox relative fill ${isTownshipRangeGridVisible ? "show-grid" : ""}`}
      style={{ cursor: "context-menu" }}
      ref={containerRef}>
      <ErrorBoundary>
        <MapProvider>
          <IpdbProvider>
            <Wrapper isInDrawingMode={isGeomBinOpen}>
              <HorizontalLoading />
              <TownshipRangeGrid onVisibleChange={setIsTownshipRangeGridVisible} />
              {showTimeline && (
                <Timeline
                  onClose={() => setShowTimeline(false)}
                  map={mapboxRef.current}
                />
              )}
              {hasFeature("Facility") && <FacilityHighlighter />}
              {hasFeature("Pad Scenario") && <ShowSticksOnMap />}
              <MapboxWrapper
                addLayer={localState.current.addLayer}
                drawRef={drawRef}
                id={id}
                mapboxRef={mapboxRef}
                removeLayer={removeLayer}
                setLayers={setLayers}
              />

              <Indicator map={true} message={"No Wells Available"} />
              <ZoomControl />
              <div className="navigate-control">
                {!isGeomBinOpen && <GoToTownshipPlay />}
                {!isGeomBinOpen && <Screenshot />}
                <MapFullscreen onFullscreen={onFullscreen} />
              </div>
              <MapToolbar className="absolute pin-topleft">
                <AddPolygon portalRef={portalRef} drawRef={drawRef} />
                <DeletePolygon portalRef={portalRef} drawRef={drawRef} />
                <MapLock
                  onMapLockChanged={(val) => {
                    const mapbox = mapboxRef.current;
                    if (mapbox) {
                      // Only sync the map if the view is not locked
                      mapSyncRef.current = !val;
                      setMapSync(mapSyncRef.current);
                    }
                  }}
                  getAndSetFeaturesIntersectingPolygon={
                    getAndSetFeaturesIntersectingPolygon
                  }
                  mapSyncRef={mapSyncRef}
                  setMapSync={setMapSync}
                />
                <LassoSelection portalRef={portalRef} drawRef={drawRef} />
                <GeomBinDrawer portalRef={portalRef} drawRef={drawRef} />
                <XdaLineMarker
                  localState={localState}
                  getAndSetFeaturesIntersectingPolygon={
                    getAndSetFeaturesIntersectingPolygon
                  }
                  drawRef={drawRef}
                  selectionPolygonRef={selectionPolygonRef}
                />
                <MapVis
                  addLayer={localState.current.addLayer}
                  removeLayer={removeLayer}
                  mapbox={mapboxRef.current}
                  filterId={filterId}
                  txnId={txnId}
                />
                {!isGeomBinOpen && (
                  <>
                    <Ipdb />
                    <BaseTooltip text="Development Timeline">
                      <BaseIconToggle
                        toggle={() => setShowTimeline(!showTimeline)}
                        className="item">
                        <PlayArrow fontSize="large"></PlayArrow>
                      </BaseIconToggle>
                    </BaseTooltip>
                  </>
                )}
                {IS_DEV_ENV && (
                  <BaseTooltip text="Debug Map Hooks" className="item">
                    <BaseIconToggle
                      onClick={() => dispatch(setIsDebuggingMap(!isDebuggingMap))}
                      squareIcon
                      className={isDebuggingMap ? "isActive" : ""}>
                      <BugReportIcon fontSize="large" />
                    </BaseIconToggle>
                  </BaseTooltip>
                )}
              </MapToolbar>
              <WellDetail />

              <IpdbLegend
                location="map"
                parentDimensions={
                  containerWidth && containerHeight
                    ? { width: containerWidth, height: containerHeight }
                    : null
                }
              />
              {IS_INTERNAL_ENV && (
                <GoToSyncWells mapbox={mapboxRef.current} onSync={onSyncChanged} />
              )}
              <BaseLayerToggle />

              <MapMessage />
              <BubbleVisLegend
                parentDimensions={{ width: containerWidth, height: containerHeight }}
                screenshotLegend={false}
              />
              <div ref={portalRef} />
            </Wrapper>
          </IpdbProvider>
        </MapProvider>
      </ErrorBoundary>
    </div>
  );
}

const Wrapper = styled.div`
  padding-top: 30px;
  padding-left: 35px;
  position: relative;
  height: 100%;

  .mapboxgl-ctrl-ruler {
    display: ${(props) => (props.isInDrawingMode ? "none" : "block")};
  }
`;
