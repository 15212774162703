import { FC } from "react";

import { Tooltip } from "antd";
import classnames from "classnames";
import styled from "styled-components/macro";
import formatPropertyName from "utils/column/formatPropertyName";
import getColumnLabelWithContext from "utils/column/getColumnLabelWithContext";

import { useColumns, useGlobalProductionSettings } from "hooks";

import { BaseButton } from "components/base";
import { IconSpinner } from "components/icons";
import { useUserDefaults } from "components/user/hooks";

import { useFacilityColumns } from "../../../hooks/useColumns";
import { EntityKind } from "../../../models/entityKind";

type PresetFieldsT = {
  entityKind: EntityKind;
  onSelect: (preset: string) => void;
};

const PresetFields: FC<PresetFieldsT> = ({ onSelect, entityKind }) => {
  // state from store
  const globalSettings = useGlobalProductionSettings(entityKind);
  const columns = useColumns();
  const facilityColumns = useFacilityColumns();
  const defaults = useUserDefaults();

  const tiles =
    entityKind == EntityKind.Well
      ? defaults?.focusTiles?.tiles ?? []
      : defaults?.facilityFocusTiles?.tiles ?? [];
  const getColumnsByEntityKind = () =>
    entityKind === EntityKind.Well ? columns : facilityColumns;

  // classes
  const getButtonClasses = (property) =>
    classnames({ isSelected: globalSettings.groupBy?.property === property });

  const tileColumns = tiles?.reduce((list, tile) => {
    const propertyName = formatPropertyName(tile);
    const column = getColumnsByEntityKind().find((c) => propertyName === c.property);
    if (!column) return list;

    list.push(column);
    return list;
  }, []);

  if (tileColumns?.length === 0) return <StyledSpinner />;

  return (
    <Wrapper>
      {tileColumns.map((column) => {
        const tile = column.property;
        const containsFcst = tile.includes(".fcst");
        const label = getColumnLabelWithContext(
          column,
          !!globalSettings.normalizeBy,
          globalSettings.normalizeBy,
          containsFcst,
          entityKind
        );

        return (
          <Tooltip
            title={column.tooltip}
            key={tile}
            mouseEnterDelay={0.5}
            mouseLeaveDelay={0}>
            <BaseButton
              className={getButtonClasses(tile)}
              appearance="none"
              onClick={() => onSelect(tile)}>
              {label?.lightContextTitle ?? column.title}
            </BaseButton>
          </Tooltip>
        );
      })}
    </Wrapper>
  );
};

export default PresetFields;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 6px;
  padding: 10px 15px;
  overflow-y: overlay;
`;

const StyledSpinner = styled(IconSpinner)`
  margin: auto auto;
`;
