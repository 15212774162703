import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ALL_CHART_TYPES } from "constants/chart.constants";
import { RootState } from "store/rootReducer";
import styled from "styled-components/macro";

import { Uwi } from "models";
import { EntityKind } from "models/entityKind";

import useTypeWellSeries from "../../arps/hooks/useTypeWellSeries";
import { useChartState } from "../context";
import {
  boxPlotDataTable,
  crossPlotDataTable,
  declineDataTable,
  getTotalRateDateDataTable,
  probitDataTable,
  stackedBarDataTable,
  typeWellDataTable,
  wellContributionDataTable
} from "../helpers";
import { useChartDependencies } from "./index";

export interface ChartDataTableColumn {
  key: string;
  name: string;
  width?: number;
  frozen?: boolean;
  resizable: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatter?: (props: any) => JSX.Element;
}

export interface WellSources {
  forecastSources: string[];
  productionDataSources: string[];
}

export interface ChartDataTableRow {
  [name: string]: number | string;
}

interface DataTable {
  columns: ChartDataTableColumn[];
  rows: ChartDataTableRow[];
}

const useDataTableData = (
  axisMinMax
): {
  loading: boolean;
  title: string;
  columns: ChartDataTableColumn[];
  rows: ChartDataTableRow[];
  sources: WellSources;
} => {
  // state
  const [columns, setColumns] = useState<ChartDataTableColumn[]>([]);
  const [rows, setRows] = useState<ChartDataTableRow[]>([]);
  const lineSeriesCharts = [
    ALL_CHART_TYPES.RateCum.label,
    ALL_CHART_TYPES.TotalRateCum.label,
    ALL_CHART_TYPES.RateTime.label,
    ALL_CHART_TYPES.RateDate.label,
    ALL_CHART_TYPES.CAGR.label,
    ALL_CHART_TYPES.BaseDeclineRate.label,
    ALL_CHART_TYPES.CumTime.label,
    ALL_CHART_TYPES.TotalRateDate.label,
    ALL_CHART_TYPES.TrendDate.label,
    ALL_CHART_TYPES.WellContribution.label
  ];
  // hooks
  const { response: responseData, settings, entityKind } = useChartState();
  const { normalizeTypeWell } = useChartDependencies(entityKind);
  const { typeWellSeries } = useTypeWellSeries(normalizeTypeWell);
  const globalGroupBy = useSelector((state: RootState) =>
    entityKind === EntityKind.Well
      ? state.groupBy.globalGroupBy
      : state.groupBy.globalFacilityFocus
  );
  const [chartFocus, setChartFocus] = useState(settings.groupBy ?? globalGroupBy);

  const chartType = settings?.chartType;

  useEffect(() => {
    if (settings.useChartFocus) {
      setChartFocus(settings.groupBy ?? globalGroupBy);
      return;
    }
    setChartFocus(globalGroupBy);
  }, [settings.groupBy, globalGroupBy]);

  useEffect(() => {
    if (!responseData) return;
    const dataTableColumns: ChartDataTableColumn[] = [
      {
        key: "Series",
        name: chartFocus.title,
        width: 175,
        frozen: true,
        resizable: false,
        formatter(props) {
          if (
            chartFocus.title == "Entity Name" ||
            (settings.sum && lineSeriesCharts.includes(chartType))
          ) {
            const series = new Uwi().toFormatted(props.row.Series);
            return (
              <SeriesNameContainer>
                <SeriesColorRect style={{ backgroundColor: props.row.SeriesColor }} />
                {series}
              </SeriesNameContainer>
            );
          }
          return (
            <SeriesNameContainer>
              <SeriesColorRect style={{ backgroundColor: props.row.SeriesColor }} />
              {props.row.Series}
            </SeriesNameContainer>
          );
        }
      }
    ];
    const dataTableRows: ChartDataTableRow[] = [];

    let dataTable: DataTable;
    switch (chartType) {
      case ALL_CHART_TYPES.CrossPlot.label: {
        dataTable = crossPlotDataTable(responseData);
        break;
      }
      case ALL_CHART_TYPES.Probit.label: {
        dataTable = probitDataTable(responseData);
        break;
      }
      case ALL_CHART_TYPES.TotalRateDate.label: {
        dataTable = getTotalRateDateDataTable(responseData, axisMinMax);
        break;
      }

      case ALL_CHART_TYPES.WellContribution.label: {
        dataTable = wellContributionDataTable({ responseData, axisMinMax });
        break;
      }

      case ALL_CHART_TYPES.CumTime.label:
      case ALL_CHART_TYPES.TotalRateCum.label:
      case ALL_CHART_TYPES.RateCum.label:
      case ALL_CHART_TYPES.RateDate.label:
      case ALL_CHART_TYPES.CAGR.label:
      case ALL_CHART_TYPES.BaseDeclineRate.label:
      case ALL_CHART_TYPES.TrendDate.label:
      case ALL_CHART_TYPES.RateTime.label:
      case ALL_CHART_TYPES.MaterialBalanceTime.label: {
        dataTable = declineDataTable(responseData, axisMinMax);
        break;
      }
      case ALL_CHART_TYPES.BoxPlot.label: {
        dataTable = boxPlotDataTable(responseData);
        break;
      }
      case ALL_CHART_TYPES.StackedBar.label: {
        // consider the chart a histogram if the x-axis is the same as the chart focus
        dataTable = stackedBarDataTable(
          responseData,
          settings.showHistogram ||
            settings.chartTypeParam.x.property === chartFocus.property
        );
        break;
      }
    }

    dataTableColumns.push(...dataTable.columns);
    dataTableRows.push(...dataTable.rows);

    // Add type formatting for the columns
    const columns = dataTableColumns.map((c) => {
      if (c.formatter) {
        return c;
      }

      return {
        ...c,
        formatter(props) {
          const value = props.row[props.column.key];

          return isNaN(value) && typeof value === "string" ? (
            <TextValueWrapper>
              {c.name == "UWI" ? new Uwi().toFormatted(value) : value}
            </TextValueWrapper>
          ) : (
            <NumericValueWrapper>{value}</NumericValueWrapper>
          );
        }
      };
    });

    // The type well data does not come from the chart service like the well data does.
    // It is generated from the arps-wasm library and will not be part of the response data.
    if (settings.typewells) {
      const typeWellData = typeWellDataTable({
        typeWellSeries,
        responseData,
        axisMinMax
      });
      dataTableRows.push(...typeWellData.rows);
    }

    setColumns(columns);
    setRows(dataTableRows);
  }, [chartType, responseData, typeWellSeries, settings.typewells]);

  return {
    loading: responseData === undefined,
    title: responseData?.title ?? "",
    columns,
    rows,
    sources: responseData?.wellSources ?? {
      productionDataSources: [],
      forecastSources: []
    }
  };
};

export default useDataTableData;

const SeriesNameContainer = styled.div`
  font-family: var(--fontMono);
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SeriesColorRect = styled.div`
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  display: inline-block;
  border-radius: 5px;
  border-color: white;
`;

const NumericValueWrapper = styled.div`
  font-family: var(--fontMono);
  font-size: 14px;
  text-align: right;
`;

const TextValueWrapper = styled.div`
  font-family: var(--fontMono);
  font-size: 14px;
  text-align: right;
`;
