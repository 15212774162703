import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import AllInboxIcon from "@material-ui/icons/AllInbox";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Button, Divider, Popover, Space, Switch, Typography } from "antd";
import axios from "axios";
import { ALL_CHART_TYPES } from "constants/chart.constants";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { BinType, IGroupBy, QuantileState } from "models";
import { mBinSize } from "models/binSize";
import { EntityKind } from "models/entityKind";

import { useChartDispatch, useChartState } from "components/chart/context";
import { BinSettings } from "components/filter";
import { ColorPaletteListToggle } from "components/filter/focus";
import GroupBy from "components/groupBy";
import { useUserDefaults } from "components/user/hooks";

const { Text } = Typography;
const dataRoot = process.env.REACT_APP_DATA_ROOT;

const WellOperatorGroupBy: IGroupBy = {
  title: "Operator",
  property: "Header.Operator_Short",
  groupByField: "Header.Operator_Short",
  pdenSource: "Public",
  canBin: false,
  dataType: "text",
  categoryId: 1,
  tooltip: "",
  display: "",
  entityKind: EntityKind.Well,
  bin: {
    BinSize: "",
    MinSize: "",
    GreaterThan: "",
    BinType: "BinSize",
    LessThan: ""
  }
};

const ChartFocus = () => {
  const { settings, entityKind } = useChartState();
  const dispatch = useChartDispatch();
  const userDefaults = useUserDefaults();
  const isWellContributionChart =
    settings.chartType === ALL_CHART_TYPES.WellContribution.label;
  const [useChartFocus, setUseChartFocus] = useState(settings.useChartFocus ?? false);
  const globalGroupBy = useSelector((state: RootState) => state.groupBy.globalGroupBy);
  const globalFacilityGroupBy = useSelector(
    (state: RootState) => state.groupBy.globalFacilityFocus
  );
  const initialChartFocus =
    entityKind === EntityKind.Facility ? globalFacilityGroupBy : globalGroupBy;
  const filterId = useSelector((state: RootState) => state.filter.filterId);
  const [chartFocus, setChartFocus] = useState(settings.groupBy ?? initialChartFocus);
  const [bin, setBin] = useState<mBinSize>(settings.groupBy?.bin ?? globalGroupBy.bin);

  const globalPalette = useSelector(
    (state: RootState) => state.userSetting.activeColorPalette
  );

  const [focusColorPalette, setFocusColorPalette] = useState(globalPalette);

  const handleColorPaletteChange = (p) => {
    const nextValue = { ...settings, colorPalette: p };
    dispatch({ type: "settings", payload: nextValue });
    setFocusColorPalette(p);
  };

  const handleFocusChange = (
    groupBy,
    isForecastToggleOn,
    selectedForecastFolder,
    selectedForecastFolderName,
    selectedPdenSource
  ) => {
    const gb = {
      ...groupBy,
      pdenSource: selectedPdenSource
    };
    setChartFocus(gb);
    setBin({
      ...bin,
      UseDynamicBins: userDefaults.binSettings?.useDynamicBins ?? false
    });
  };

  const { refetch } = useQuery(
    "bin-size",
    () => {
      return axios.get(
        `${dataRoot}/api/v1/data/bin-size/${filterId}/${btoa(
          encodeURIComponent(chartFocus.property)
        )}?normalizeField=%27%27&per=0&useDynamicBins=${bin?.UseDynamicBins}`
      );
    },
    {
      enabled: false,
      // need to wait for the default bin response
      onSuccess: (data) => {
        if (data?.data?.isValid) {
          // updates the chart focus for values that can be binned
          const newBin = data.data;
          let binned: mBinSize = {
            LessThan: newBin.lessThan,
            GreaterThan: newBin.greaterThan,
            MinSize: newBin.minSize,
            BinSize: newBin.binSize,
            BinType: "BinSize",
            Quantile: null,
            UseDynamicBins: bin.UseDynamicBins ?? false
          };
          if (
            userDefaults.binSettings?.type === "Quantile" &&
            (chartFocus.dataType.toLowerCase() === "number" ||
              chartFocus.dataType.toLowerCase() === "integer")
          ) {
            binned = {
              ...binned,
              BinType: "Quantile",
              Quantile: {
                quantileType: userDefaults.binSettings.quantile.quantileType,
                numQuantiles: userDefaults.binSettings.quantile.numQuantiles
              }
            };
          }
          setBin(binned);
          const groupBy = {
            ...chartFocus,
            groupbyField: chartFocus.property,
            bin: binned
          };
          const nextValue = {
            ...settings,
            useChartFocus: useChartFocus,
            colorPalette: focusColorPalette,
            groupBy: groupBy
          };
          dispatch({ type: "settings", payload: nextValue });
        } else {
          // updates the chart focus for values without binning
          let defaultBin: mBinSize = {
            LessThan: "",
            GreaterThan: "",
            MinSize: "",
            BinSize: "",
            BinType: "BinSize",
            Quantile: null,
            UseDynamicBins: bin.UseDynamicBins ?? false
          };
          if (
            userDefaults.binSettings?.type === "Quantile" &&
            (chartFocus.dataType.toLowerCase() === "number" ||
              chartFocus.dataType.toLowerCase() === "integer")
          ) {
            defaultBin = {
              ...defaultBin,
              BinType: "Quantile",
              Quantile: {
                quantileType: userDefaults.binSettings.quantile.quantileType,
                numQuantiles: userDefaults.binSettings.quantile.numQuantiles
              }
            };
          }
          setBin(defaultBin);
          const groupBy = {
            ...chartFocus,
            groupbyField: chartFocus.property,
            bin: defaultBin
          };
          const nextValue = {
            ...settings,
            useChartFocus: useChartFocus,
            colorPalette: focusColorPalette,
            groupBy: groupBy
          };
          dispatch({ type: "settings", payload: nextValue });
        }
      }
    }
  );

  function handleBinChange(val) {
    const newBin = Object.assign({}, bin, val);
    setBin(newBin);
  }

  function updateBin(binType: BinType, quantile: QuantileState, useDynamicBins: boolean) {
    let newBinType = binType;
    // Show type as dynamic but function as bin size besides useDynamicBins
    if (binType === "Dynamic") {
      newBinType = "BinSize";
    }
    const newBin = { ...bin };
    newBin.BinType = newBinType;
    newBin.Quantile = quantile;
    newBin.UseDynamicBins = useDynamicBins;
    setBin(newBin);
    const updatedFocus = {
      ...chartFocus,
      groupByField: chartFocus.property,
      bin: newBin
    };
    const nextValue = {
      ...settings,
      useChartFocus: useChartFocus,
      colorPalette: focusColorPalette,
      groupBy: updatedFocus
    };
    dispatch({ type: "settings", payload: nextValue });
  }

  // keeps the chart focus the same as the global focus when not being used
  useEffect(() => {
    if (!useChartFocus) {
      if (entityKind === EntityKind.Facility) {
        if (isWellContributionChart) {
          setChartFocus(WellOperatorGroupBy);
        } else {
          setChartFocus(globalFacilityGroupBy);
        }
      } else {
        setChartFocus(globalGroupBy);
      }
    }
  }, [globalGroupBy, globalFacilityGroupBy, useChartFocus]);

  // keeps the chart colour palette the same as the global colour palette
  // when chart focus is not being used
  useEffect(() => {
    if (!useChartFocus) {
      setFocusColorPalette(globalPalette);
    }
  }, [globalPalette, useChartFocus]);

  // updates the chart focus
  useEffect(() => {
    const nextValue = {
      ...settings,
      useChartFocus: useChartFocus,
      colorPalette: focusColorPalette,
      groupBy: useChartFocus
        ? {
            ...chartFocus,
            groupbyField: chartFocus.property,
            //override entitykind to well if well contribution chart
            //because group by is well fields not facility fields
            entityKind: isWellContributionChart ? EntityKind.Well : entityKind
          }
        : globalGroupBy
    };
    if (useChartFocus) {
      // need to obtain/set bin settings if using chart-specific focus
      refetch();
      return;
    }
    dispatch({ type: "settings", payload: nextValue });
  }, [useChartFocus, chartFocus]);

  // refetch bins when switching from dynamic to static bins
  useEffect(() => {
    if (useChartFocus) {
      refetch();
    }
  }, [bin.UseDynamicBins]);

  return (
    <StyledSpace direction="vertical" split={<StyledDivider />}>
      <HeaderWrapper>
        <Text strong>Chart Focus</Text>
        <StyledSwitch
          data-testid="survivorBiasCB"
          size="small"
          checked={useChartFocus}
          onChange={(checked) => {
            setUseChartFocus(checked);
            if (checked) {
              setBin({
                ...bin,
                UseDynamicBins: userDefaults.binSettings?.useDynamicBins ?? false
              });
            }
          }}
        />
      </HeaderWrapper>
      {useChartFocus && (
        <FocusArea>
          <GroupBy
            onChange={handleFocusChange}
            value={chartFocus}
            //well contribution chart groups by well fields not facility fields
            entityKind={isWellContributionChart ? EntityKind.Well : entityKind}>
            <FocusButton block>
              {chartFocus.title}
              <KeyboardArrowRightIcon style={{ fontSize: 24 }} />
            </FocusButton>
          </GroupBy>
          <Popover
            trigger="click"
            placement="topLeft"
            content={
              <FocusBinContainer>
                <BinSettings
                  bin={bin}
                  canEditBin={chartFocus.canBin}
                  onReset={refetch}
                  onBinChange={(v) => handleBinChange(v)}
                  onBinSettingChange={updateBin}
                  isMinCountWarningVisible={false}
                />
              </FocusBinContainer>
            }>
            <FocusBinButton icon={<AllInboxIcon style={{ fontSize: 18 }} />} />
          </Popover>
          <ColorPaletteSelector>
            <ColorPaletteListToggle
              activePalette={focusColorPalette}
              onPaletteSelected={handleColorPaletteChange}
              showBorder={true}
            />
          </ColorPaletteSelector>
        </FocusArea>
      )}
    </StyledSpace>
  );
};

const HeaderWrapper = styled.div`
  width: 100%;
  min-width: 0;
  display: inline-flex;
  align-items: center;
  gap: 6px;

  .ant-switch-checked:focus {
    box-shadow: none;
  }
`;

const StyledSwitch = styled(Switch)`
  margin-left: auto;
`;

const StyledSpace = styled(Space)`
  display: flex;
  min-width: 200px;
`;

const StyledDivider = styled(Divider)`
  margin: 0;
`;

const FocusButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  text-align: left;

  & > span {
    max-width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const FocusArea = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 1fr;
`;

const FocusBinContainer = styled.div`
  width: 36rem;
  background-color: var(--color-text-06);
  display: grid;
  gap: 1px;
`;

const FocusBinButton = styled(Button)`
  background: transparent;
  color: #a2aaad;
  display: grid;
  justify-content: center;
  align-items: center;

  &:hover {
    color: var(--color-selection);
  }

  &:focus {
    color: var(--color-selection);
  }
`;

const ColorPaletteSelector = styled.div`
  border: none;
  height: 32px;
  width: 32px;

  &:hover {
    border-color: var(--color-selection);
    color: var(--color-selection);
  }

  &:focus {
    border-color: var(--color-selection);
    color: var(--color-selection);
  }
`;
export default ChartFocus;
